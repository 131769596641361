import { useState, useEffect } from 'react'
import { supabase } from './supabaseClient'
import Avatar from './Avatar'

export default function Account({ session }) {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState(null)
  const [website, setWebsite] = useState(null)
  const [avatar_url, setAvatarUrl] = useState(null)

  const [ips, setIps] = useState([])

  const [newIp, setNewIp] = useState('')

  useEffect(() => {
    getProfile()
  }, [session])

  async function getProfile() {
    try {
      setLoading(true)
      const {data:{user}} = await supabase.auth.getUser()

      let { data, error } = await supabase
        .from('ip_addresses')
        .select(`ipv4, is_active, created_at`)
        .limit(5)
        .order('created_at', { ascending: false })
        .eq('user_id', user.id);

      if (error) {
        throw error
      }

      setIps(data)

    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function updateAccess() {
    setLoading(true)
    const result = await supabase.functions.invoke('yolo', {body:{ip: newIp}})
    setNewIp('')
    getProfile()
    setLoading(false)
  }

  return (
    <div className="form-widget">
      <div>
        <label htmlFor="email">Email</label>
        <input id="email" type="text" value={session.user.email} disabled />
      </div>
      <div>
        <label htmlFor="ip">New IP to add</label>
        <input id="ip" type="text" value={newIp} onChange={(e) => {setNewIp(e.target.value.trim())}} />
      </div>

      <div>
        <button
          className="button block primary"
          onClick={() => updateAccess()}
          disabled={loading}
        >
          {loading ? 'Loading ...' : 'Click here save your current IP address'}
        </button>
      </div>

      <div>
        <pre>
        {JSON.stringify(ips, null, 2)}
        </pre>
      </div>

      <div>
        <button className="button block" onClick={() => supabase.auth.signOut()}>
          Sign Out
        </button>
      </div>
    </div>
  )
}
